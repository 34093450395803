<template>
  <div
    class="flex flex-row items-center small-shadow bg-white rounded-lg card-gradient cursor-pointer single-category hover:text-white py-8 px-6 gap-5"
  >
    <div class="rounded-full flex flex-column bg-blue-200 p-4 items-center">
      <!-- <img class="mx-auto" src="../../assets/svg/Vector.svg" /> -->
      <span class="uppercase initials">
        {{ getInitials(category.name) }}
      </span>
    </div>
    <ul class="flex-column">
      <li class="flex-column font-semibold text-sm capitalize">
        {{ category.name }}
      </li>
      <li class="text-xs">
        {{
          `${
            "total_product" in category
              ? category.total_product || 0
              : category.product_count || 0
          } Product(s)`
        }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CategoryInfo",
  props: {
    category: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getInitials(stringValue = "") {
      const split = stringValue.split(" ");
      if (split.length > 1) {
        return `${split[0].charAt(0)}${split[1].charAt(0)}`;
      } else {
        return `${split[0].charAt(0)}${split[0].charAt(1)}`;
      }
      // debugger
    },
  },
};
</script>

<style lang="postcss" scoped>
.single-category:hover > div > div {
  @apply text-blue-700;
}
.card-gradient:hover {
  background: linear-gradient(180deg, #0143aa 0%, #003283 100%);
}
</style>
