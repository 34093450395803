<template>
  <div>
    <trac-loading v-if="isLoading" />
    <div v-else>
      <!-- Sync Loading UI -->
      <trac-dual-sync
        v-if="isACleanSlate"
        :status="'syncing'"
        :progress="progress"
      ></trac-dual-sync>
      <!-- Normal Loading UI -->
      <main v-else class="md:pl-12 pr-4 md:pr-0">
        <div v-if="!allCategories || !allCategories.length" class="h-128">
          <div
            class="flex flex-col text-center items-center justify-center h-full"
          >
            <span class="font-semibold text-xl text-gray-500"
              >No categories are available.<br />Create a new category by
              clicking
            </span>
            <div class="mx-auto mt-8">
              <create-category
                v-if="
                  permissionsModules &&
                  permissionsModules[4].parent.permissionsForUser.createCategory
                "
                @close="fetchAllCategories"
              >
                <template #default="{ toggleModal }">
                  <trac-button
                    class="uppercase text-xs"
                    @button-clicked="toggleModal"
                  >
                    New Category
                  </trac-button>
                </template>
              </create-category>
              <p v-else class="text-sm">No permissions to view this.</p>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-row items-end justify-between mb-6">
            <div class="flex-column mt-12">
              <!-- <h2>Products</h2> -->
              <h3 class="font-bold mt-4 text-lg">
                Categories ({{ allCategories ? allCategories.length : 0 }})
              </h3>
            </div>
            <div
              v-if="
                allCategories &&
                permissionsModules &&
                permissionsModules[4].parent.permissionsForUser
                  .createCategory &&
                isOnline
              "
              class="flex-column"
            >
              <create-category @close="fetchAllCategories">
                <template #default="{ toggleModal }">
                  <trac-button
                    class="uppercase text-xs"
                    @button-clicked="toggleModal"
                  >
                    New Category
                  </trac-button>
                </template>
              </create-category>
            </div>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <div v-for="(category, i) in allCategories" :key="i">
              <category-info
                :category="category"
                @click.native="
                  $router.push({ path: `${$route.path}/${category.id}` })
                "
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import AdvancedSearch from "../../offline-module/advancedSearch";
import { cleanSlate } from "../../offline-module/offline.store";
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID,
} from "../../browser-db-config/localStorage";
import { permissionsModules } from "./../settings/permission";

import { eventBus } from "./../../main";
// import categories from "../../store/modules/categories";
import { mapActions, mapGetters } from "vuex";
import CreateCategory from "./CreateCategory.vue";
import CategoryInfo from "./CategoryInfo.vue";

export default {
  components: { CreateCategory, CategoryInfo },
  name: "ProductCategories",
  data() {
    return {
      isLoading: false,
      progress: {
        name: "",
        recordsDone: 0,
        total: 0,
      },
      selectedCategory: {},
      subCategoryList: [],
      showSubCategoryModal: false,
      permissionsModules,
      adv: new AdvancedSearch({
        BusinessId: GET_USER_BUSINESS_ID(),
        offlineManager: this.$GlobalOfflineManager,
      }),
    };
  },
  async created() {
    await this.NetworkCheck();
    this.dualSyncStatus = "syncing";
    await this.loadOfflineRecords();
    this.isLoading = true;
    DELETE_LOCAL_DB_DATA("category-detail");
    await this.fetchAllCategories();
    this.isLoading = false;
  },
  watch: {
    SWData(newValue, oldValue) {
      console.log("Categories -> SWData, ", newValue); // For debugging/checking this fires in console.log
      if (this.isADownloadOnlySyncEvent(newValue, "categories")) {
        // this.dualSyncStatus = false;
        console.log("category download event detected");
        cleanSlate.moduleList["categories"] = false;
        this.loadOfflineRecords();
      }
      if (this.isAProgressSyncEvent(newValue, "categories")) {
        this.progress = newValue.data;
      }
    },
  },
  computed: {
    ...mapGetters({
      allCategories: "GET_PRODUCT_CATEGORIES",
    }),
    computedCategoryList() {
      return this.subCategoryList.map((cat) => {
        return { name: cat.category.name, _id: cat.category._id };
      });
    },
    sortedCategories() {
      return this.categories.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    isACleanSlate() {
      return cleanSlate.moduleList["categories"] === true;
    },
  },
  methods: {
    ...mapActions(["FETCH_PRODUCTS_CATEGORIES"]),
    async fetchAllCategories() {
      this.isLoading = true;
      try {
        await this.FETCH_PRODUCTS_CATEGORIES();
      } catch (error) {
        return eventBus.$emit("trac-alert", {
          message: error?.response?.data?.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    selectCategoryFromDropdown(data) {
      console.log("emitted from dropdown: ", data);
      const category = this.categories.find(
        (cat) => cat.category.name === data.name
      );
      if (category) {
        this.gotoCategoryDetail(category);
      }
    },
    async loadOfflineRecords() {
      // const results = await this.adv.paginateRecords({
      //   tableName: "categories",
      //   pageIndex: 0,
      //   loopThrough: true,
      //   resultsPerSearch: 30,
      // })
      // this.categories = results
      this.categories = await this.$GlobalOfflineManager.getAll("categories");
      this.isLoading = false;
    },
    gotoCategoryDetail(category) {
      console.log("proceeding to save category details: ", category);
      SAVE_LOCAL_DB_DATA("category-detail", category);
      this.$router.push({ name: "CategoriesList" });
    },
    gotoAddCategory() {
      DELETE_LOCAL_DB_DATA("category-detail");
      this.$router.push({ name: "AddCategories" });
    },
  },
};
</script>

<style scoped lang="postcss">
.little-shadow {
  box-shadow: 0px 0px 10px 0px #1678b014;
}
</style>
