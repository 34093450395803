var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('trac-loading'):_c('div',[(_vm.isACleanSlate)?_c('trac-dual-sync',{attrs:{"status":'syncing',"progress":_vm.progress}}):_c('main',{staticClass:"md:pl-12 pr-4 md:pr-0"},[(!_vm.allCategories || !_vm.allCategories.length)?_c('div',{staticClass:"h-128"},[_c('div',{staticClass:"flex flex-col text-center items-center justify-center h-full"},[_c('span',{staticClass:"font-semibold text-xl text-gray-500"},[_vm._v("No categories are available."),_c('br'),_vm._v("Create a new category by clicking ")]),_c('div',{staticClass:"mx-auto mt-8"},[(
                _vm.permissionsModules &&
                _vm.permissionsModules[4].parent.permissionsForUser.createCategory
              )?_c('create-category',{on:{"close":_vm.fetchAllCategories},scopedSlots:_vm._u([{key:"default",fn:function({ toggleModal }){return [_c('trac-button',{staticClass:"uppercase text-xs",on:{"button-clicked":toggleModal}},[_vm._v(" New Category ")])]}}],null,false,235097676)}):_c('p',{staticClass:"text-sm"},[_vm._v("No permissions to view this.")])],1)])]):_c('div',[_c('div',{staticClass:"flex flex-row items-end justify-between mb-6"},[_c('div',{staticClass:"flex-column mt-12"},[_c('h3',{staticClass:"font-bold mt-4 text-lg"},[_vm._v(" Categories ("+_vm._s(_vm.allCategories ? _vm.allCategories.length : 0)+") ")])]),(
              _vm.allCategories &&
              _vm.permissionsModules &&
              _vm.permissionsModules[4].parent.permissionsForUser
                .createCategory &&
              _vm.isOnline
            )?_c('div',{staticClass:"flex-column"},[_c('create-category',{on:{"close":_vm.fetchAllCategories},scopedSlots:_vm._u([{key:"default",fn:function({ toggleModal }){return [_c('trac-button',{staticClass:"uppercase text-xs",on:{"button-clicked":toggleModal}},[_vm._v(" New Category ")])]}}],null,false,235097676)})],1):_vm._e()]),_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5"},_vm._l((_vm.allCategories),function(category,i){return _c('div',{key:i},[_c('category-info',{attrs:{"category":category},nativeOn:{"click":function($event){return _vm.$router.push({ path: `${_vm.$route.path}/${category.id}` })}}})],1)}),0)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }