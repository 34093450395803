<template>
  <div>
    <slot :toggle-modal="toggleModal" />

    <trac-loading v-if="loading" />
    <trac-modal
      v-else-if="showModal && !loading"
      @close="
        () => {
          toggleModal();
          formData.name = '';
          formData.description = '';
        }
      "
    >
      <h1 class="mt-8 mb-12 text-xl font-bold text-accentDark">
        Create Category
      </h1>

      <div>
        <form @submit.prevent="submit" class="flex flex-col gap-6">
          <div>
            <trac-input
              v-model="formData.name"
              type="text"
              placeholder="Category"
            />
            <trac-input-error v-if="error.name">
              Category field is required
            </trac-input-error>
          </div>

          <trac-input
            v-model="formData.description"
            placeholder="Description"
          />

          <div class="flex justify-center mt-20">
            <trac-button
              class="uppercase text-xs"
              :disabled="!fieldsValid"
              type="submit"
            >
              Add Category
            </trac-button>
          </div>
        </form>
      </div>
    </trac-modal>

    <trac-success-modal v-if="showSuccessModal" @close="close">
      <p>Category has been created successfully.</p>
    </trac-success-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { eventBus } from "../../eventBus";

export default {
  name: "CreateCategory",
  emits: ["close"],
  data() {
    return {
      showModal: false,
      showSuccessModal: false,
      formData: {},
      loading: false,
      error: {},
    };
  },
  computed: {
    fields() {
      return {
        name: {
          valid: /[a-zA-Z0-9]/giu.test(this.formData?.name || ""),
        },
      };
    },
    fieldsValid() {
      return Object.values(this.fields).every(({ valid }) => !!valid);
    },
  },
  methods: {
    validator() {
      return Object.entries(this.fields).every(([key, { valid }]) => {
        this.error[key] = !valid;
        console.log(valid, this.error, key);
        return !!valid;
      });
    },
    ...mapActions(["CREATE_PRODUCTS_CATEGORY"]),
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleSuccessModal() {
      this.showSuccessModal = !this.showSuccessModal;
    },
    switchView() {
      this.toggleModal();
      this.toggleSuccessModal();
    },
    async submit() {
      const isValid = this.validator();
      if (isValid) {
        this.loading = true;
        try {
          const res = await this.CREATE_PRODUCTS_CATEGORY([this.formData]);
          if (res.status) {
            this.switchView();
          }
        } catch (error) {
          return eventBus.$emit("trac-alert", {
            message: error?.response?.data?.message,
          });
        } finally {
          this.loading = false;
        }
      }
    },
    close() {
      this.toggleSuccessModal();
      this.formData.name = "";
      this.formData.description = "";
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
